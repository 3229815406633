import { Button, Table, Space, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { parseFilter } from '@/utils/filter';
import { useTranslation } from 'react-i18next';
import { FilterByRegex, FilterByIcon, FilterByStatus } from '../utils/CommonFilters';
import { RawPage } from '@/interfaces/page.interface';

const getPreviewUrl = () => import.meta.env.VITE_APP_PREVIEW_URL;

const STATUS_COLOR = {
  publish: 'green',
  'soft-delete': 'red',
  pending: 'red',
  draft: 'blue',
};

export interface PagesTableProps {
  data?: RawPage[];
  total?: number;
  current?: number;
  loading?: boolean;
  onFilter?: (value: any) => any;
  onEdit?: (data: RawPage) => void;
  onView?: (data: RawPage) => void;
  onDelete?: (id: string) => void;
}

const PagesTable = ({ data, total, loading, onFilter, onEdit }: PagesTableProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const Columns: ColumnsType<RawPage> = [
    {
      title: t('general.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (_id, row) => {
        return (
          <Button
            type="link"
            rel="noreferrer"
            href={`${getPreviewUrl()}/${language as 'en' | 'es'}/page/${
              row.slug[language as 'en' | 'es']
            }`}
            target="_blank"
            style={{
              textAlign: 'left',
              whiteSpace: 'normal',
            }}
          >
            {row.name[language as 'en' | 'es']}
          </Button>
        );
      },
      filterDropdown: FilterByRegex,
      filterIcon: FilterByIcon,
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      key: 'status',
      defaultFilteredValue: ['publish'],
      render: (status: keyof typeof STATUS_COLOR) => {
        return <Tag color={STATUS_COLOR[status]}>{t(`status.${status}`)}</Tag>;
      },
      filters: [
        {
          text: t('status.publish'),
          value: 'publish',
        },
        {
          text: t('status.draft'),
          value: 'draft',
        },
        {
          text: t('status.soft-delete'),
          value: 'soft-delete',
        },
      ],
      filterDropdown: FilterByStatus([
        { label: t('status.publish'), value: 'publish' },
        { label: t('status.draft'), value: 'draft' },
        { label: t('status.soft-delete'), value: 'soft-delete' },
      ]),
    },
    {
      title: t('general.createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (created_at: string) =>
        created_at && new Date(created_at).toLocaleDateString(),
    },
    {
      title: t('general.actions'),
      key: '_id',
      render: (text: string, record: RawPage) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit?.(record)}>
            {t('general.edit')}
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnChange = (pagination: any = {}, filters: any = {}, sorter: any) => {
    if (onFilter) {
      onFilter((value: Record<string, string>) => ({
        ...parseFilter(filters, value, sorter),
        language,
        $skip: pagination.current ? (pagination.current - 1) * 10 : 0,
      }));
    }
  };

  return (
    <Table
      className="custom-table"
      dataSource={data}
      columns={Columns}
      loading={loading}
      onChange={handleOnChange}
      rowKey={(e) => e._id}
      pagination={{
        total,
        defaultPageSize: 10,
        showTotal: (total) => t('general.total', { total }),
      }}
    />
  );
};

export default PagesTable;
